import * as v from "valibot";
import { Show, createEffect, createSignal } from "solid-js";
import { useTranslation } from "~/i18n";
import { useSession } from "~/session";
import { Icon } from "~/components/icon";
import { Button } from "~/components/button";
import { cx, run } from "~/utils";

type Props = {
  initialError: string | null;
};

const MAX_FILE_SIZE_MB = 5;
const mimeTypes: `${string}/${string}`[] = ["image/jpeg", "image/png", "image/heic"];
export const schema = v.pipe(v.instance(File), v.mimeType(mimeTypes), v.maxSize(1024 * 1024 * MAX_FILE_SIZE_MB));

export const ImageUpload = (props: Props) => {
  let heicToAny;
  const [session] = useSession();
  const { T, t } = useTranslation(session.locale);

  const [file, setFile] = createSignal<File | null>(null);
  const [error, setError] = createSignal(props.initialError);
  const [uploading, setUploading] = createSignal(false);

  let submitRef!: HTMLInputElement;
  let fileRef!: HTMLInputElement;

  const handleChange = (ev: Event) => {
    const file = (ev.currentTarget as HTMLInputElement).files?.[0] ?? null;

    if (!file) {
      return;
    }

    // Convert HEIC to PNG - Heic is only supported on iOS devices
    if (file?.type === "image/heic") {
      run(async () => {
        const heicToAnyImport = await import("heic2any");
        heicToAny = heicToAnyImport.default;

        const convertedHeic = await (heicToAny as (options: { blob: Blob; toType: string; quality: number }) => Promise<Blob>)({
          blob: file,
          toType: "image/jpeg",
          quality: 0.8,
        });

        const convertedFile = new File([convertedHeic as BlobPart], "pet", { type: "image/jpeg" });

        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(convertedFile);
        fileRef.files = dataTransfer.files;

        setFile(convertedFile);
      });
    } else {
      setFile(file);
    }
  };

  createEffect(() => {
    if (!file()) {
      return;
    }

    const { success, issues } = v.safeParse(schema, file());

    if (!success) {
      // Check if it's a size-related error
      if (issues[0].message.includes("size")) {
        setError(t("upload.label.error").replace("{limit}", `${MAX_FILE_SIZE_MB} MB`));
      } else {
        // Show non localized error messages if its a generic one
        setError(issues[0].message);
      }
    } else {
      setError(null);
      setUploading(true);
      submitRef.click();
    }
  });

  return (
    <div class="flex flex-col items-center gap-2">
      <form action="/confirm" method="post" enctype="multipart/form-data" class="contents size-full">
        <label
          class={cx({
            "group relative": true,
            "pointer-events-none opacity-50": uploading(),
          })}
          onClick={(ev: Event) => {
            if (ev.target !== submitRef) {
              window.analytics?.track("Upload Picture Button Clicked");
            }
          }}
        >
          <input ref={submitRef} type="submit" class="hidden" />
          <input
            ref={fileRef}
            type="file"
            name="image"
            class="absolute inset-0 z-10 size-full cursor-pointer opacity-0"
            accept={mimeTypes.join(", ")}
            onChange={handleChange}
          />
          <div class="contents md:hidden">
            <Button after={<Icon name="img" />} class="flex flex-wrap">
              <T term={uploading() ? "upload.label.uploading" : "upload.label.short"} />
            </Button>
          </div>
          <div class="hidden md:contents">
            <div
              class={cx([
                "flex w-80 flex-col items-center gap-2 py-6 text-center",
                "rounded-md border-2 border-dashed border-blue bg-blue-50 text-blue",
                "group-hover:border-solid group-hover:bg-blue-20",
                "group-has-[:focus-visible]:ring-1 group-has-[:focus-visible]:ring-blue/40 group-has-[:focus-visible]:ring-offset-1",
                "transition-colors duration-100 ease-in",
              ])}
            >
              <Icon name="img" />
              <T as="p" term={uploading() ? "upload.label.uploading" : "upload.label.long"} class="text-md [&_strong]:font-semibold" />
              <T as="p" term="upload.label.footer" class="text-sm font-light" />
            </div>
          </div>
        </label>
      </form>
      <Show when={error()}>
        <p class="mx-auto max-w-[28ch] text-center text-sm text-coral">{error()}</p>
      </Show>
    </div>
  );
};
